import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-content'>
        <p>
          &copy; {new Date().getFullYear()} Skapad av &nbsp;
          <a href="https://techlara.se" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Techlära</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
