import { useParams } from 'react-router-dom';
import { useState } from 'react';
import townsData from '../../data/towns_data';
import HotelInList from './hotel';
import Banner from '../Header/banner';
import './hotels.css';
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import routeData from '../../data/route_data';

const Hotels = () => {

  const { townName } = useParams();
  const [dateFrom, onChangeFrom] = useState(new Date());
  const [dateTo, onChangeTo] = useState(new Date(Date.now() + 5 * 24 * 60 * 60 * 1000)); // add 5 days as default value
  const [numberOfAdults, setNumberOfAdults] = useState('2');
  const [numberOfRooms, setNumberOfRooms] = useState('1');

  const handleNumberOfAdults = (event) => {
    setNumberOfAdults(event.target.value);
  }

  const handleNumberOfRooms = (event) => {
    setNumberOfRooms(event.target.value);
  }
  const createRouteBase = (townName) => {
    const route = routeData.routes.find((route) => route.from === townName) ? routeData.routes.find((route) => route.from === townName) : routeData.routes.find((route) => route.to === townName);
    return route.route + ": " + route.name + " - " + route.distance;
  }

  const getRouteInfo = (townName) => {
    const route = routeData.routes.find((route) => route.from === townName) ? routeData.routes.find((route) => route.from === townName) : routeData.routes.find((route) => route.to === townName);
    return route.information2 ? route.information2 : route.information
  }
  const createdTownsData = () => {
    return townsData.map(town => {
      console.log("town", town);
      return {
        ...town,
        routeBase: createRouteBase(town.name),
        routeInfo: getRouteInfo(town.name),
      }
    })
  }

  const townData = createdTownsData();

  const createLinkFromTown = ({ routeTo }) => {
    console.log("routeTo", routeTo);
    const baseHotelURL = "https://sv.hotels.com/Hotel-Search?";
    return `${baseHotelURL}destination=${routeTo}%2C%20Sverige&flexibility=0_DAY
      &adults=2
      &rooms=1
      &sort=RECOMMENDED&afflid=1101lyCvZbPg`;
    // &poi=&latLong=${town.coordinates.lat}%2C${town.coordinates.lng}
    // &mapBounds=${town.mapBounds.northEast.lat}%2C${town.mapBounds.northEast.lng}
    // &mapBounds=${town.mapBounds.southWest.lat}%2C${town.mapBounds.southWest.lng}
    //TODO Add locale for hotels.com
  };


  return (
    <div >
      <Banner />
      <br />
      <div className='hotels-container'>


        <h1>{townName}</h1>

        {townData.map((town, index) => (
          town.name === townName && (
            <div key={index}>
              {town.information}

              <br /><br />
              <h2>{town.routeBase}</h2>
              {town.routeInfo}
              <br /><br /><br />

              
              {/* <div className='from-to-container'>
                <div className='from-container'>
                  Från: {" "}<br />
                  <DatePicker
                    onChange={onChangeFrom}
                    value={dateFrom}
                    minDate={new Date()}
                    format='yyyy-MM-dd'
                    className="datepicker"
                  />
                </div>

                <div className='to-container'>
                  Till: {" "}<br />
                  <DatePicker
                    onChange={onChangeTo}
                    value={dateTo}
                    minDate={new Date()}
                    format='yyyy-MM-dd'
                  />
                </div>
              </div>

              <div className='adults-container'>
                Antal vuxna: {" "}
                <input className='text-field text-field-number' type="number" value={numberOfAdults} onChange={handleNumberOfAdults} />
              </div>

              <br />
              <div className='rooms-container'>
                Antal rum: {" "}
                <input className='text-field text-field-number' type="number" value={numberOfRooms} onChange={handleNumberOfRooms} />
              </div> */}

              <h1>Bästa cykelhotellen i {townName}</h1>
              <br />
                {/* {town.hotels.map((hotel, index) => (
                  <HotelInList key={index} hotel={hotel} />
                ))} */}

                <a href={createLinkFromTown({ routeTo: townName })} style={{ fontSize: '20px' }}>Boka Hotell här</a>
                <br /><br /><br /><br />
            </div>
          )
        ))}
      </div>
    </div>
  );
}

export default Hotels;
