import React from 'react';
import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css';
import './hotel.css';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

function HotelInList({ hotel }) {

  const bookClicked = (link) => {
  window.open(link, '_blank');
  }

  const LeftNav = ({ onClick }) => (
    <button
      type="button"
      className="image-gallery-left-nav image-gallery-nav-button image-button"
      onClick={onClick}
    >
      <SlArrowLeft size={25} className='left-arrow'/>
    </button>
  );

  const RightNav = ({ onClick }) => (
    <button
      type="button"
      className="image-gallery-right-nav image-gallery-nav-button image-button"
      onClick={onClick}
    >
      <SlArrowRight size={25} className='right-arrow'/>
    </button>
  );

  return (
    <div className='hotel-container'>
      <h2>{hotel.name}</h2>
      <ImageGallery 
        items={hotel.images} 
        showPlayButton={false} 
        showFullscreenButton={false}
        thumbnailPosition='right' 
        renderRightNav={(onClick) => (
          <RightNav onClick={onClick} />
        )}
        renderLeftNav={(onClick) => (
          <LeftNav onClick={onClick} />
        )}
      />
      
      <div className='description'>
        {hotel.description}
      </div>
      <div className='container'>
        <div className='price'>
        <strong>Price: </strong>
        {hotel.price}
        </div>
        <div className='rating'>
          <strong>Rating: </strong>
          {hotel.rating}
        </div>
      </div>
      <div >
        <button className='cta-button' onClick={() => bookClicked(hotel.link)}>Boka nu</button>
      </div>
    <div className='hotel-separator'></div>
    </div>
  );
}

export default HotelInList;