import room1  from '../../img/room1.jpeg';
import room2  from '../../img/room2.jpg';
import room1_thumb  from '../../img/room1_thumb.jpg';
import room2_thumb  from '../../img/room2_thumb.jpg';


export default {
    room1,
    room2,
    room1_thumb,
    room2_thumb
};

