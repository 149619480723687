import React, { useState } from 'react';
import townsData from '../../data/towns_data';
import './towns.css';
import routeData from '../../data/route_data';

const Towns = () => {

  const handleTownClick = (townName) => {
    window.location.href = `/${townName}`;
  }


  const createRouteBase = (townName) => {
    
    const route = routeData.routes.find((route) => route.from === townName);
    console.log("route", route);
    if (!route) {
      return "No route found"; // or handle this case appropriately
    }
    return route.route + ": " + route.name + " - " + route.distance;
  }
  
  const getRouteInfo = (townName) => {
    const route = routeData.routes.find((route) => route.from === townName);
    if (!route) {
      return "No route found"; // or handle this case appropriately
    }
    return route.information2 ? route.information2 : route.information
  }
  const createdTownsData = () => {
    return townsData.map(town => {
      console.log("town", town);
      return {
        ...town,
        routeBase: createRouteBase(town.name),
        routeInfo: getRouteInfo(town.name),
      }
    })
  }

  const townData = createdTownsData();
  
  return (
    <div >
      <div className="grid-container">
        {townData.map((town) => (
          <div 
            key={town.name} 
            className="town-container" 
            onClick={() => handleTownClick(town.name)}
          >
            <h2 className='town-name'>{town.name}</h2>
            <img className="town-image" src={town.image} alt={town.name} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Towns;
