import React, { } from 'react';
import './infotext.css'

const InfoText = () => {

  const plan = `      Att planera och boka din cykelresa längs Kattegattleden har aldrig varit enklare. Vi ger dig all information du behöver och hjälper dig att hitta de bästa hotellen på varje etapp för att din semesterresa ska rulla på så enkelt som möjligt. Förutom planeringen av hotell samlar vi även annan information som kan vara bra att tänka på när du  cykelsemestrar på Kattegattleden.

  Tips och inspiration: Upptäck de bästa sevärdheterna, restaurangerna och upplevelserna längs Kattegattleden genom våra omfattande guider och rekommendationer.')
  doc.add_paragraph('- Praktiska råd: Läs om praktisk information som hjälper dig att förbereda dig för resan, inklusive tips om cykelsäkerhet, servicepunkter och hur du bäst respekterar allemansrätten.')


  Genom att använda Cyklakattegattleden.se kan du känna dig trygg i att du har allt du behöver för en minnesvärd och välorganiserad cykelresa.`;

  const routes = `Etapp 1: Helsingborg till Ängelholm 61 km
  Starta din resa i Helsingborg, känd för sin mångfald av restauranger och pittoreska kustlinje. Höganäs erbjuder dramatiska naturupplevelser och charmiga hamnar där du kan ta en paus och njuta av lokal mat och dryck.

  Etapp 2: Ängelholm till Båstad 55 km
  Njut av Ängelholms sandstränder och fortsätt mot Båstad, känt för sina tennis- och golfbanor samt natursköna vandringsleder.
  
  Etapp 3: Båstad till Halmstad 42 km
  
  Etapp 4: Halmstad Falkenberg 57 km
  
  Etapp 5: Falkenberg till Varberg 39 km
  
  Etapp 6 Varberg till Kungsbacka 72 km
  Varberg erbjuder allt från spa och nudistbad till historiska Varbergs fästning. Fortsätt till Kungsbacka och besök Tjolöholms slott, Sveriges enda tudorslott.
  
  Etapp 7 Kungsbacka till Göteborg 64 km
  Denna sträcka passerar genom historiska områden och vackra landskap. Upptäck Kungsbackas gamla träbyggnader och avsluta i Göteborgs livliga stadskärna där du kan njuta av fisk och skaldjur på Feskekörka eller besöka kulturhistoriska platser.')
  
  Planera din cykelresa efter Kattegattledens etapper och välj den kategori av boende som passar dig bäst. Vi rekommenderar dig att boka boende i förväg för att försäkra dig om en smidig resa hela vägen så att du med säkerhet har ett boende som du vill ha på varje ort. Med hjälp av vår kunskap och praktiska struktur blir det enkelt för dig att boka din cykelresa.`

  const places = `- Hotell Kullaberg, Mölle: Ett charmigt hotell med fantastisk utsikt över havet, perfekt för att koppla av efter en dag på cykeln.
- Varbergs Stadshotell & Asia Spa, Varberg: Njut av lyx och avkoppling på detta hotell med tillgång till ett prisbelönt spa.')
- Clarion Hotel Post, Göteborg: Beläget mitt i Göteborgs centrum, erbjuder detta hotell bekvämligheter och enkel tillgång till stadens sevärdheter.')

Bed and Breakfast på cykelresan på Kattegattleden
- Kullagårdens Wärdshus, Höganäs: En mysig vistelse med hemtrevliga rum och närhet till naturen.
- Wapnö Gårdshotell, Halmstad: Upplev gårdsmiljö och lokala delikatesser i detta charmiga B&B.
. Pensionat Frillesberg, Frillesås: Ett familjedrivet pensionat med personlig service och bekväma rum.

Vandrarhem för cykelresan
- Halmstad Vandrarhem, Halmstad: Ett budgetvänligt alternativ med rena och bekväma rum, perfekt för cyklister.
- Ängelholms Vandrarhem, Ängelholm: Beläget nära stranden och centrum, erbjuder detta vandrarhem en prisvärd vistelse.')
- STF Vandrarhem Göteborg Stigbergsliden, Göteborg: Centralt beläget med enkel tillgång till stadens attraktioner och cykelvägar.

Camping som alternativ på Kattegattleden
- Råå Vallar Resort, Helsingborg: En populär campingplats med bra faciliteter och närhet till stranden.
- Apelviken Camping, Varberg: Perfekt för naturälskare med fina badmöjligheter och cykelvänliga omgivningar.
- Lisebergsbyn Camping, Göteborg: En modern campingplats med utmärkta faciliteter och närhet till stadens sevärdheter.`;

  const practical = `
Kattegattleden är väl skyltad och erbjuder säker cykling på både asfalterade vägar och grusvägar. Kom ihåg att respektera allemansrätten och ta med dig ditt skräp. Många av destinationerna längs leden erbjuder cykelpumpar och annan service för cyklister.')

Karta och ruttbeskrivningar
För att underlätta din resa har vi detaljerade kartor och ruttbeskrivningar tillgängliga. Du kan också ladda ner GPX-filer för navigering direkt i din cykeldator eller smartphone.

Bokning och kontakt
Har du frågor eller behöver mer information? Kontakta oss via e-post på info@cyklakattegattleden.se eller ring oss på 0123-456 789. Vi finns här för att hjälpa dig att planera din perfekta cykelresa längs Kattegattleden.

Upptäck friheten på två hjul och skapa minnen för livet. Boka ditt boende idag och börja ditt äventyr på Kattegattleden redan i sommar!

För mer information, besök https://www.kattegattleden.se`;

const about = `Erik Svedin är entreprenören med 17 år inom cykelbranschen med en tonvikt på cykelturism och upplevelser med cykel där jag har drivit flera olika bolag och varit drivande i att utveckla svensk cykelturism med paketerade cykelresor. Jag har arbetat som guide och cykeluthyrare och drivit Touroperator och paketerat cykelresor till resebyråer. “Tidigare behövdes en resebyrå eller touroperator för att paketera cykelresor för att göra det enkelt och smidigt. Med vårt nya Ai verktyg kan du på ett enkelt sätt planera din cykelresa helt själv och spara 1000-tals kronor på bara en veckas cykelsemestrande på Kattekattleden men ändå få en välplanerad semester!” ”

Johannes Olsson
Johannes är techentreprenör med 10+ års erfarenhet inom webbdesign och utveckling. Han har varit med och utvecklat flera webbplatser och applikationer.`

  const textSegment = ({ header, text }) => {
    return (
      <div>
        <h2>{header}</h2>
        <div className='info-text'>
          {text}
        </div>
        <br/>
      </div>
    )
  }

  return (
    <div className='info-text-container'>
    {textSegment({ header: "Planera och boka din cykelresa med Cyklakattegattleden.se", text: plan })}
      
      {textSegment({ header: "Kattegattledens etapper", text: routes })}

      {textSegment({ header: "Boka de bästa hotellen för cykelsemestern på Kattegattleden", text: places })}

      {textSegment({ header: "Praktisk information", text: practical })}

      {textSegment({ header: "Om oss", text: about })}
      

      Meta description: Cykla Kattegattleden – Prisvärd cykelsemester längs Sveriges första nationella cykelled! Boka boende, upptäck etapperna, få tips & inspiration. Planera din drömresa enkelt med Cyklakattegattleden.se!
      <br /><br /><br /><br /><br />
    </div>
  );
}

export default InfoText;

