import helsingborg from '../../../src/img/helsingborg.jpg';
import angelholm from '../../../src/img/angelholm.jpg';
import halmstad from '../../../src/img/halmstad.webp';
import varberg from '../../../src/img/varberg.webp';
import falkenberg from '../../../src/img/falkenberg.jpg';
import kungsbacka from '../../../src/img/kungsbacka.webp';
import goteborg from '../../../src/img/goteborg.jpeg';
import images from "../data/images";

import React from 'react';

const replaceImageSize = (url) => url.replace("max1280x900", "max300x300");

const townsData = [
  {
    name: "Helsingborg",
    information: "Starta din resa i Helsingborg, känd för sin mångfald av restauranger och pittoreska kustlinje. Höganäs erbjuder dramatiska naturupplevelser och charmiga hamnar där du kan ta en paus och njuta av lokal mat och dryck.",
    image: helsingborg,
    routeBase: "",
    routeInfo: "",
    coordinates: "56.046071, 12.693045",
    hotels: [
      {
        name: 'Marina Plaza',
        price: "100 SEK",
        city: "Helsingborg",
        slug: 'marina-plaza',
        rating: "5 stars",
        description: "Det bästa med Elite Hotel Marina Plaza är dess fantastiska läge vid hamnen, precis intill färjeterminalen och centralstationen. Gästerna uppskattar den vackra utsikten över Öresund och den generösa frukostbuffén som ofta lovordas​​​​.",
        link: "https://www.booking.com/hotel/se/elite-marina-plaza.sv.html",
        images: [
          {
            original: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/75300222.jpg?k=28e4485319cde27c6962feb2bd0682152299c6278b77b9a2c5da0be9f0fcdb6c&o=&hp=1",
            thumbnail: replaceImageSize("https://cf.bstatic.com/xdata/images/hotel/max1280x900/75300222.jpg?k=28e4485319cde27c6962feb2bd0682152299c6278b77b9a2c5da0be9f0fcdb6c&o=&hp=1"),
          },
          {
            original: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/28839226.jpg?k=193829c4a83de98db370188191e848d78b365fac06428a6df757940c75f207c1&o=&hp=1",
            thumbnail: replaceImageSize("https://cf.bstatic.com/xdata/images/hotel/max1280x900/28839226.jpg?k=193829c4a83de98db370188191e848d78b365fac06428a6df757940c75f207c1&o=&hp=1"),
          },
          {
            original: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/274145765.jpg?k=cfb0d0eef9e54012fbb36b4ad9af946912b9e086ce2bbffa8eeda6791ccf46ad&o=&hp=1",
            thumbnail: replaceImageSize("https://cf.bstatic.com/xdata/images/hotel/max1280x900/274145765.jpg?k=cfb0d0eef9e54012fbb36b4ad9af946912b9e086ce2bbffa8eeda6791ccf46ad&o=&hp=1"),
          },
        ],
      },
      {
        name: 'Clarion Grand Hotel',
        price: "100 SEK",
        city: "Helsingborg",
        slug: 'clarion-grand-hotel',
        rating: "5 stars",
        description: "Clarion Grand Hotel i Helsingborg erbjuder en charmig och historisk atmosfär med moderna bekvämligheter. Hotellets centrala läge gör det enkelt att utforska staden, och deras uppskattade frukostbuffé är en höjdpunkt för många gäster.",
        link: "https://www.booking.com/hotel/se/clarion-grand.sv.html",
        images: [
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
        ],
      },
      {
        name: 'Elite Hotel Mollberg',
        price: "100 SEK",
        city: "Helsingborg",
        slug: 'elite-hotel-mollberg',
        rating: "5 stars",
        description: "Elite Hotel Mollberg är känt för sin eleganta och historiska byggnad från 1800-talet. Dess centrala läge vid Stortorget gör det till ett perfekt val för dem som vill uppleva Helsingborgs kulturella och kulinariska erbjudanden.",
        link: "https://www.booking.com/hotel/se/elite-mollberg.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
    ],
  },
  {
    name: "Ängelholm",
    information: "Sveriges trevligaste stad. Fantastiska restauranger, bra och varierad shopping och ett stort kulturutbud – i Helsingborg finns något fr alla. Här kan du flanera längs Sveriges äldsta gågata, Kullagatan, botanisera bland butikerna och ta en skn fikapaus på något av de mysiga caféerna i centrum. Brinner du fr historia, vackra slott eller naturupplevelser lär du inte bli besviken. I centrala Helsingborg kan du beundra utsikten ver Öresund från det medeltida tornet Kärnan, beska vackra Sankta Maria kyrka och gra en utflykt till kungliga Sofiero slott med sin fantastiska slottssträdgård. På Fredriksdals friluftsmuseum kan du vandra i historiska trädgårdar och fascineras av kor, hästar, grisar och får av gamla tiders lantraser, och på Dunkers kulturhus finns spännande utställningar, året om. Helsingborg är en av Sveriges krogtätaste städer. Utbudet är varierat både när det gäller mat och atmosfär – här hittar du allt från mysiga krypin runt Mariatorget till saltstänkta restauranger med underbar utsikt ver sundet.",
    image: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/75300222.jpg?k=28e4485319cde27c6962feb2bd0682152299c6278b77b9a2c5da0be9f0fcdb6c&o=&hp=1",
    routeBase: "Etapp 2-3: Ängelholm till Båstad 55 km",
    routeInfo: `Njut av Ängelholms sandstränder och fortsätt mot Båstad, känt för sina tennis- och golfbanor samt natursköna vandringsleder.
`,
    coordinates: "56.046071, 12.693045",
    hotels: [
      {
        name: 'Best Western Hus 57',
        price: "100 SEK",
        city: "Ängelholm",
        slug: 'best-western-hus-57',
        rating: "5 stars",
        description: "Det bästa med Best Western Hus 57 är dess moderna design och bekväma faciliteter, inklusive en härlig spaavdelning och en välrenommerad restaurang som serverar lokala delikatesser.",
        link: "https://www.booking.com/hotel/se/best-western-paletten.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
      {
        name: 'Riverside Hotel & Apartments',
        price: "100 SEK",
        city: "Ängelholm",
        slug: 'riverside-hotel-apartments',
        rating: "5 stars",
        description: "Riverside Hotel & Apartments erbjuder en lugn och naturskön miljö vid Rönne å, med bekväma rum och lägenheter som passar både korta och längre vistelser.",
        link: "https://www.booking.com/hotel/se/riverside-angelholm.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
      {
        name: 'Hotel Lilton',
        price: "100 SEK",
        city: "Ängelholm",
        slug: 'hotel-lilton',
        rating: "5 stars",
        description: "Hotel Lilton är ett charmigt boutiquehotell med personlig service och hemtrevlig atmosfär. Gästerna uppskattar den närliggande parken och den korta promenaden till centrala Ängelholm.",
        link: "https://www.booking.com/hotel/se/lilton-angelholm.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
    ],
  },
  {
    name: "Halmstad",
    information: "Sveriges trevligaste stad. Fantastiska restauranger, bra och varierad shopping och ett stort kulturutbud – i Helsingborg finns något fr alla. Här kan du flanera längs Sveriges äldsta gågata, Kullagatan, botanisera bland butikerna och ta en skn fikapaus på något av de mysiga caféerna i centrum. Brinner du fr historia, vackra slott eller naturupplevelser lär du inte bli besviken. I centrala Helsingborg kan du beundra utsikten ver Öresund från det medeltida tornet Kärnan, beska vackra Sankta Maria kyrka och gra en utflykt till kungliga Sofiero slott med sin fantastiska slottssträdgård. På Fredriksdals friluftsmuseum kan du vandra i historiska trädgårdar och fascineras av kor, hästar, grisar och får av gamla tiders lantraser, och på Dunkers kulturhus finns spännande utställningar, året om. Helsingborg är en av Sveriges krogtätaste städer. Utbudet är varierat både när det gäller mat och atmosfär – här hittar du allt från mysiga krypin runt Mariatorget till saltstänkta restauranger med underbar utsikt ver sundet.",
    image: halmstad,
    routeBase: "Etapp 2: Ängelholm till Båstad 55 km",
    routeInfo: `Cyklingen fortsätter norrut mot en av Sveriges mest bermda och vackraste stränder,Tylsand. Om du inte vill ta ett bad på den av livräddare bevakade stranden, tycker jag att du ska beska hotell Tylsand. På hotellet finns Sveriges strsta fotokonstgalleri och ett ”museum” som visar en del av Gyllene Tider och Roxettes historia. Leden fljer kusten norrut och passerar Haverdal och Särdal. Stanna till på Steninge kuststation fr en fika i Plätteriet eller Bikebar. Du behver inte cykla så långt innan du kommer till Ugglarps Havsbad och Långasand, båda med fina bad och restauranger. Efter en kort tur inåt landet kommer du åter ut till kusten och Grimsholmen.Här är idealiskt fr ett stopp och en kort promenad ut vattnet och kanske njuta av en medhavd matsäck. Du cyklar vidare och är snart framme i Falkenberg och Skrea Strand med sin långa badbrygga.`,    
    coordinates: "56.046071, 12.693045",
    hotels: [
      {
        name: 'Hotel Continental',
        price: "100 SEK",
        city: "Halmstad",
        slug: 'hotel-continental',
        rating: "5 stars",
        description: "Hotel Continental erbjuder lyxig boende med spa och wellnessfaciliteter. Det centrala läget gör det enkelt att upptäcka Halmstads attraktioner.",
        link: "https://www.booking.com/hotel/se/continental.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
      {
        name: 'Hotel Tylösand',
        price: "100 SEK",
        city: "Halmstad",
        slug: 'hotel-tylosand',
        rating: "5 stars",
        description: "Hotel Tylösand erbjuder en fantastisk strandupplevelse med moderna rum och ett brett utbud av spa- och wellnessfaciliteter. Hotellets konstsamling är också en attraktion i sig.",
        link: "https://www.booking.com/hotel/se/tylosand.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
    ],
  },
  
  {
    name: "Falkenberg",
    information: "Sveriges trevligaste stad. Fantastiska restauranger, bra och varierad shopping och ett stort kulturutbud – i Helsingborg finns något fr alla. Här kan du flanera längs Sveriges äldsta gågata, Kullagatan, botanisera bland butikerna och ta en skn fikapaus på något av de mysiga caféerna i centrum. Brinner du fr historia, vackra slott eller naturupplevelser lär du inte bli besviken. I centrala Helsingborg kan du beundra utsikten ver Öresund från det medeltida tornet Kärnan, beska vackra Sankta Maria kyrka och gra en utflykt till kungliga Sofiero slott med sin fantastiska slottssträdgård. På Fredriksdals friluftsmuseum kan du vandra i historiska trädgårdar och fascineras av kor, hästar, grisar och får av gamla tiders lantraser, och på Dunkers kulturhus finns spännande utställningar, året om. Helsingborg är en av Sveriges krogtätaste städer. Utbudet är varierat både när det gäller mat och atmosfär – här hittar du allt från mysiga krypin runt Mariatorget till saltstänkta restauranger med underbar utsikt ver sundet.",
    image: "https://cf.bstatic.com/xdata/images/hotel/max1280x900/75300222.jpg?k=28e4485319cde27c6962feb2bd0682152299c6278b77b9a2c5da0be9f0fcdb6c&o=&hp=1",
    routeBase: "",
    routeInfo: "Etapp 6: ",
    coordinates: "56.046071, 12.693045",
    hotels: [
      {
        name: 'Ocean Hotel',
        price: "100 SEK",
        city: "Falkenberg",
        slug: 'ocean-hotel',
        rating: "5 stars",
        description: "Ocean Hotel ligger precis vid stranden och erbjuder en avkopplande miljö med modern komfort och vacker havsutsikt.",
        link: "https://www.booking.com/hotel/se/ocean.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
    
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
      {
        name: 'Grand Hotel Falkenberg',
        price: "100 SEK",
        city: "Falkenberg",
        slug: 'grand-hotel-falkenberg',
        rating: "5 stars",
        description: "Grand Hotel Falkenberg erbjuder eleganta rum och en restaurang med utsikt över Ätran, vilket ger gästerna en minnesvärd vistelse.",
        link: "https://www.booking.com/hotel/se/grand-falkenberg.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
      {
        name: 'Hotel Strandbaden',
        price: "100 SEK",
        city: "Falkenberg",
        slug: 'hotel-strandbaden',
        rating: "5 stars",
        description: "Hotel Strandbaden erbjuder ett fantastiskt läge vid havet, med moderna bekvämligheter och en avkopplande atmosfär.",
        link: "https://www.booking.com/hotel/se/elite-strandbaden.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
    ],
  },
  {
    name: "Varberg",
    information: "Sveriges trevligaste stad. Fantastiska restauranger, bra och varierad shopping och ett stort kulturutbud – i Helsingborg finns något fr alla. Här kan du flanera längs Sveriges äldsta gågata, Kullagatan, botanisera bland butikerna och ta en skn fikapaus på något av de mysiga caféerna i centrum. Brinner du fr historia, vackra slott eller naturupplevelser lär du inte bli besviken. I centrala Helsingborg kan du beundra utsikten ver Öresund från det medeltida tornet Kärnan, beska vackra Sankta Maria kyrka och gra en utflykt till kungliga Sofiero slott med sin fantastiska slottssträdgård. På Fredriksdals friluftsmuseum kan du vandra i historiska trädgårdar och fascineras av kor, hästar, grisar och får av gamla tiders lantraser, och på Dunkers kulturhus finns spännande utställningar, året om. Helsingborg är en av Sveriges krogtätaste städer. Utbudet är varierat både när det gäller mat och atmosfär – här hittar du allt från mysiga krypin runt Mariatorget till saltstänkta restauranger med underbar utsikt ver sundet.",
    image: varberg,
    routeInfo: "",
    coordinates: "56.046071, 12.693045",
    hotels: [
      {
        name: 'Hotel Fregatten',
        price: "100 SEK",
        city: "Varberg",
        slug: 'hotel-fregatten',
        rating: "5 stars",
        description: "Hotel Fregatten ligger nära både havet och Varbergs centrum, och erbjuder bekväma rum samt en uppskattad frukostbuffé.",
        link: "https://www.booking.com/hotel/se/clarion-collection-fregatten.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
      {
        name: 'Varbergs Kusthotell',
        price: "100 SEK",
        city: "Varberg",
        slug: 'varbergs-kusthotell',
        rating: "5 stars",
        description: "Varbergs Kusthotell är känt för sitt spa och fantastiska läge vid havet, vilket gör det till en perfekt plats för avkoppling och välbefinnande.",
        link: "https://www.booking.com/hotel/se/comwell-varbergs-kurort.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
      {
        name: 'Hotel Gästis',
        price: "100 SEK",
        city: "Varberg",
        slug: 'hotel-gastis',
        rating: "5 stars",
        description: "Hotel Gästis erbjuder en unik upplevelse med sin historiska atmosfär och det berömda Leninbadet. Hotellets centrala läge är också en fördel.",
        link: "https://www.booking.com/hotel/se/hotell-gastis.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
    ],
  },
  {
    name: "Kungsbacka",
    information: "Sveriges trevligaste stad. Fantastiska restauranger, bra och varierad shopping och ett stort kulturutbud – i Helsingborg finns något fr alla. Här kan du flanera längs Sveriges äldsta gågata, Kullagatan, botanisera bland butikerna och ta en skn fikapaus på något av de mysiga caféerna i centrum. Brinner du fr historia, vackra slott eller naturupplevelser lär du inte bli besviken. I centrala Helsingborg kan du beundra utsikten ver Öresund från det medeltida tornet Kärnan, beska vackra Sankta Maria kyrka och gra en utflykt till kungliga Sofiero slott med sin fantastiska slottssträdgård. På Fredriksdals friluftsmuseum kan du vandra i historiska trädgårdar och fascineras av kor, hästar, grisar och får av gamla tiders lantraser, och på Dunkers kulturhus finns spännande utställningar, året om. Helsingborg är en av Sveriges krogtätaste städer. Utbudet är varierat både när det gäller mat och atmosfär – här hittar du allt från mysiga krypin runt Mariatorget till saltstänkta restauranger med underbar utsikt ver sundet.",
    image: kungsbacka,
    routeInfo: "",
    coordinates: "56.046071, 12.693045",
    hotels: [
      {
        name: 'Hotell Halland',
        price: "100 SEK",
        city: "Kungsbacka",
        slug: 'hotell-halland',
        rating: "5 stars",
        description: "Hotell Halland erbjuder bekvämt boende med närhet till både Kungsbacka centrum och Göteborg. Deras frukostbuffé är en populär start på dagen.",
        link: "https://www.booking.com/hotel/se/best-western-halland.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
      {
        name: 'Säröhus hotell och spa',
        price: "100 SEK",
        city: "Kungsbacka",
        slug: 'saro-hus-hotell-spa',
        rating: "5 stars",
        description: "Särö Havsbad är känt för sin avkopplande miljö vid havet och erbjuder spa-faciliteter samt utmärkt mat i sin restaurang.",
        link: "https://www.booking.com/hotel/se/sarohus-konferenshotell.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
      {
        name: 'Nattmössan',
        price: "100 SEK",
        city: "Kungsbacka",
        slug: 'nattmossan',
        rating: "5 stars",
        description: "Hotell Nattmössan erbjuder en hemtrevlig och prisvärd vistelse med enkel tillgång till både Kungsbacka och Göteborg.",
        link: "https://www.booking.com/hotel/se/hotell-nattmossan.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
    ],
  },
  {
    name: "Göteborg",
    information: "Sveriges trevligaste stad. Fantastiska restauranger, bra och varierad shopping och ett stort kulturutbud – i Helsingborg finns något fr alla. Här kan du flanera längs Sveriges äldsta gågata, Kullagatan, botanisera bland butikerna och ta en skn fikapaus på något av de mysiga caféerna i centrum. Brinner du fr historia, vackra slott eller naturupplevelser lär du inte bli besviken. I centrala Helsingborg kan du beundra utsikten ver Öresund från det medeltida tornet Kärnan, beska vackra Sankta Maria kyrka och gra en utflykt till kungliga Sofiero slott med sin fantastiska slottssträdgård. På Fredriksdals friluftsmuseum kan du vandra i historiska trädgårdar och fascineras av kor, hästar, grisar och får av gamla tiders lantraser, och på Dunkers kulturhus finns spännande utställningar, året om. Helsingborg är en av Sveriges krogtätaste städer. Utbudet är varierat både när det gäller mat och atmosfär – här hittar du allt från mysiga krypin runt Mariatorget till saltstänkta restauranger med underbar utsikt ver sundet.",
    image: goteborg,
    routeInfo: "",
    coordinates: "56.046071, 12.693045",
    hotels: [
      {
        name: 'Clarion Hotel Post',
        price: "100 SEK",
        city: "Göteborg",
        slug: 'clarion-hotel-post',
        rating: "5 stars",
        description: "Clarion Hotel Post kombinerar modern design med historiska element i den gamla postbyggnaden, och erbjuder utmärkta faciliteter inklusive en takpool.",
        link: "https://www.booking.com/hotel/se/clarion-post.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
      {
        name: 'Hotel Eggers',
        price: "100 SEK",
        city: "Göteborg",
        slug: 'hotel-eggers',
        rating: "5 stars",
        description: "Hotel Eggers är ett av Göteborgs äldsta hotell med en rik historia och klassisk elegans. Dess centrala läge vid Drottningtorget är en stor fördel.",
        link: "https://www.booking.com/hotel/se/hotel-eggers.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
      {
        name: 'Clarion Collection Hotel Odin',
        price: "100 SEK",
        city: "Göteborg",
        slug: 'clarion-collection-hotel-odin',
        rating: "5 stars",
        description: "Clarion Collection Hotel Odin erbjuder moderna bekvämligheter och en kvällsmåltid som ingår, vilket gör det till ett populärt val för både affärs- och fritidsresenärer.",
        link: "https://www.booking.com/hotel/se/clarion-collection-odin.sv.html",
        images: [
          {
            original: images.room1,
            thumbnail: images.room1_thumb,
          },
          {
            original: images.room2,
            thumbnail: images.room2_thumb,
          },
        ],
      },
    ],
  },
];

export default townsData;