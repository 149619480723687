import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hotels from './code/components/hotel/hotels';
import Start from './code/components/towns/start';
import { Helmet } from 'react-helmet';
import Footer from './code/components/Footer';

function App() {

  return (
    <Router>
      <Helmet>
        <title>Cykla kattegattleden</title>
        <meta name="google-site-verification" content="6pqxz6GNMDc1JxKST-hRYUDa7s1ETN7qiWI370ggjs8" />
      </Helmet>
      <div className="App">
        <div className='main-container'>
          <div className='content-container'>
            <Routes>
              <Route path="/" element={<Start />} />
              <Route path={`/:townName`} element={<Hotels />} />
            </Routes>
          </div>
        </div>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
