import React from 'react';
import banner from '../../../img/kattegatt_text.jpg';
import './banner.css';

const Banner = () => {
  return(
    <div >
      <img src={banner} alt="Hotels Banner" className='banner-image' />
    </div>
  );
}

export default Banner;