const routeData = {
    "generalInformation": "I Helsingborg startar eller slutar Kattegattleden vid Informationstavlan utanför Centralstationen, där tågstationen, färjeläget och långtidsparkering finns och etappen slutar vid Höganäs hamn. Tänk på att kolla upp öppettider på de besöksmål och anläggningar du tänkt besöka och boka gärna ditt boende i förväg! Allemansrätten är en unik möjlighet för alla att röra sig fritt i naturen. Men du behöver också ta ansvar för natur och djurliv och visa hänsyn mot markägare och andra besökare. Alltså inte störa – inte förstöra. Allemansrätten är begränsad i nationalparker och naturreservat. Det är alltid du som besökare i naturen som har ansvaret att känna till vad som gäller i området som du besöker. Tänk också på att hålla cykelleden fri från nedskräpning – ta med ditt skräp till närmaste soptunna!",
    "routes": [
      {
        "route": "Etapp 1",
        "name": "HELSINGBORG - HÖGANÄS",
        "from": "Helsingborg",
        "to": "Höganäs",
        "distance": "22 km",
        "status": "Det finns inga kända problem längs etappen.",
        "foodAndDrinks": "Gårdsbutiker och god mat är utmärkande inslag kring Helsingborg och Höganäs, då odling och jordbruk är en viktig del i denna region. Njut av havets läckerheter hos Christians Fiskebod i Domstens hamn. Strax söder om Domsten cyklar du förbi Kulla Gunnarstorp Gårdsbutik som erbjuder både närodlat, blommor och inredning. På Sofiero Gård, strax söder om Sofiero Slott finner du surdegsbageriet Brödkultur med café, som även serverar lättare frukost och lunch. För goda våfflor gör en avstickare till Pålsjöpaviljongen Café & Våffelbageri. I Viken rekommenderas en avstickare för ett besök hos Vikentomater, här kan du botanisera bland frukt, bär och grönsaker från den egna gården. Vad sägs om melon, över 100 olika sorters tomater och jordgubbar? Här kan du också avnjuta en läcker lunch tillagad på det som odlas här. Inne i Höganäs ligger området Salthallarna, här finns bland annat ett café med egen tillverkad Italiensk gelato och chokladpraliner, Garage Bar som serverar en av världens godaste burgare och ett microbyggeri.",
        "information": "Från Helsingborg cyklar du norrut, passerar Sofiero slott och de långa, härliga stränderna vid Domsten och Viken. Leden går längs vattnet, och utsikten över ett glittrande Öresund är minst sagt magisk. Kattegattleden börjar – eller slutar – i en av Nordens äldsta städer, Helsingborg. Den första sträckan tar dig från stadskärnan till Gröningen och Fria bad, två populära badplatser som lockar med glass, sol och bad. Sitt ner och njut ett tag, för snart kommer ledens första uppförsbacke. Lite längre fram ligger Sofiero slott och Sofiero Gård, med bageri och café, som är väl värt ett besök. Leden går vidare norrut och passerar små pittoreska fiskelägen och småbåtshamnar som Domsten och Viken. Strax utanför Viken kan du göra en avstickare till Beredskapsmuseet, Sveriges enda museum som huserar i en bevarad underjordisk försvarsanläggning från andra världskriget. Cykla vidare mot Höganäs och njut av utsikten över Öresund innan du kommer fram till Kvickbadet, strax söder om Höganäs hamn.",
        "information2": "När du lämnat Helsingborg City och efter en kort uppförsbacke kommer du till Sofiero slott och slottsträdgård, väl värt ett stopp speciellt om du är trädgårdsintresserad. När du fortsätter passerar du små pittoreska samhällen och småbåtshamnar som Domsten och Viken innan du kommer fram till Höganäs. Ett bra ställe för paus, mat och dryck är Höganäs hamn. Leden går vidare över vackra och natursköna Kullahalvön. Längst ut ligger naturreservatet Kullaberg med Kullens fyr, en avstickare du inte ångrar. På Kullahalvön ligger ett av Sveriges nya vindistrikt med flera vingårdar som du kan besöka. Här finns också flera restauranger, matställen och caféer. Missa inte att ta en fika hos Flickorna Lundgren på Skäret. Innan du når Ängelholm cyklar du genom flera små mysiga kustsamhällen.",
        "surface": "96% asfalt, 4% grus"
      },
      {
        "route": "Etapp 2",
        "name": "HÖGANÄS - ÄNGELHOLM",
        "from": "Höganäs",
        "to": "Ängelholm",
        "distance": "39 km",
        "status": "Det finns inga kända problem längs etappen.",
        "foodAndDrinks": "Kullabygdens Vingårdar - När du är uppe på Kullahalvön och blickar ut över landskapet får du en känsla av att vara i Toscana. Här ligger ett att Sveriges nya vindistrikt. På vingårdarna Kullahalvöns Vingård, Arilds Vingård, Vingården Villa Mathilda och Södåkra Vingård möter du entusiastiska vinbönder som gärna berättar om sina viner och erbjuder vingårdsvandringar. På Kullahalvön står mat och njutning i fokus och i området finns gott om gårdsbutiker, restauranger och caféer. På Brunnby Boställe med gårdsbutik och café och Kullasparris kan du se hur sparris odlas. Matställen utmed leden som Hotell Strandbaden 1910, Holy Smoke, Rusthållargården, Svanshalls Krog och Tunneberga Gästgivaregård är bra ställen att fylla på energin. Perfekt för ett fikastopp är Flickorna Lundgren på Skäret, ett av Sveriges äldsta och mest populära kaféer. Krapperups Café & Slottsbod, Albertsgården och Trädgårdsköket på Hedentorps plantskola är fler trevliga fikaställen på denna etapp. Etappen går till/från hamnen i Höganäs och till/från tågstationen i Ängelholm. Passa på att pumpa däcken i Höganäs kommun. Längs leden finns praktiska pumpstationer som hjälper dig vidare på din tur.",
        "information": "Du cyklar över vackra Kullahalvön som är fylld med konst, gårdsbutiker och loppisar. Sträckan är en blandning av lågt böljande, öppna fält och hög, kuperad terräng med en fantastisk utsikt. Efter Höganäs passerar du små hamnar och badstränder som Strandbaden och Nyhamnsläge. Turen fortsätter över den vackra, dramatiska Kullahalvön. Strax efter Lerhamn ligger Krapperups borg med anor från 1500-talet. Längst ut på Kullahalvön ligger naturreservatet Kullaberg, och har du tid över för en avstickare är det väl värt ett besök. Kullaberg erbjuder en variation av aktiviteter, till exempel vandring och klättring på klipporna. Den högsta punkten – Håkull – mäter 187 meter över havet. När du åter når havet passeras samhällena Arild, Skäret, Jonstorp och Norra Häljaröd och på sträckan finns flera trevliga caféer.  På väg in mot Ängelholm ligger Kronoskogen. I dess södra del, som kallas Sibirien, finns ett spännande ufo-monument.",
        "surface": "85% asfalt, 15% grus"
      },
      {
        "route": "Etapp 3",
        "name": "ÄNGELHOLM - BÅSTAD",
        "from": "Ängelholm",
        "to": "Båstad",
        "distance": "55 km",
        "status": "Det finns inga kända problem längs etappen.",
        "foodAndDrinks": "",
        "information": "Denna del av Kattegattleden är den mest kuperade sträckningen. Njut av den vackra utsikten över havet och Bjärehalvöns variationsrika jordbrukslandskap.Kattegattleden passerar centrala Ängelholm alldeles intill tåg- och busstationen och lotsar dig vidare ner till den vackra stadsdelen Havsbaden. Här möts du av skog, orörd natur och en härligt bred och lång sandstrand. Stanna till vid strandkiosken och provsmaka Engelholms Glass, stadens eget stolta glassmärke. Färden fortsätter längs kusten mot Skälderviken, genom småbyar och fiskelägen. På vägen korsar du Rönne å – ett perfekt stopp för kanotpaddling eller kanske en avkopplande paddeltur på SUP-bräda. Du passerar Skepparkroken och Vejbystrand innan du i Killebäckstorp viker av mot pittoreska Torekov. Här finns caféer, restauranger och och du kan även ta båten över till Hallands Väderö. Från Torekov går turen vidare, in i själva hjärtat av Bjäre. Här väntar ett småskaligt jordbrukslandskap, typiskt för Bjärehalvön, format och baserat på boskapsskötsel sedan förhistorisk tid.  Du möter havet igen vid Norrvikens Trädgårdar och följer leden vidare mot Båstad hamn.",
        "surface": "76% asfalt, 24% grus"
      },
      {
        "route": "Etapp 4",
        "name": "BÅSTAD - HALMSTAD",
        "from": "Båstad",
        "to": "Halmstad",
        "distance": "42 km",
        "status": "För att frigöra yta för byggandet av Södra infartens passage över Västkustbanan och anslutande hamnspår har en temporär väg med cykelväg byggts parallellt med Stålverksgatan. Läs mer och se karta här. Risk för vattenfylld viadukt under E6:an väster om Trönningen. Vid dessa tillfällen hänvisar vi till viadukten söder om nuvarande sträckning.",
        "foodAndDrinks": "",
        "information": "Denna delen av leden tar dig längs Sveriges längsta sandstrand och genom grönskande naturreservat. Från Båstad hamn följer du havet tills du når tallskogen i Hemmeslöv och passerar gränsen mellan Halland och Skåne. Nu väntar en lättrampad sträcka längs Laholmsbuktens långa sandstrand. I familjära Skummeslövsstrand hittar du restauranger och caféer. Här är havet långgrunt och landskapet öppet. Några kilometer in i landet ligger vackra Skottorps slott och mysiga Laholm, Hallands äldsta stad. Beräkna att det tar cirka 20 minuter från leden till Laholm. Ett par kilometer norr om Skummeslövsstrand når du Mellbystrand, en badort med minnen från svunna tider. Här är dynerna högre och havet vildare. Du passerar glassbutiker, minigolfbanor och uteserveringar. Norr om Mellbystrand väntar en sträcka längs med sanddyner, genom strandskog och naturreservat, förbi Laxvik och ut på den öppna landsbygden söder om Halmstad. Innan du lämnar havet kan du stanna till vid Påarps klapperstensfält med 220 järnåldersgravar. Leden passerar sedan Östra stranden innan den når Halmstad slott.",
        "surface": "75% asfalt, 25% grus"
      },
      {
        "route": "Etapp 5",
        "name": "HALMSTAD - FALKENBERG",
        "from": "Halmstad",
        "to": "Falkenberg",
        "distance": "57 km",
        "status": "Det finns inga kända problem längs etappen.",
        "foodAndDrinks": "",
        "information": "Kust och hav, öppna landskap, natursköna och fågelrika naturreservat, trevliga små gårdsbutiker… På den sköna sträckan mellan Halmstad och Falkenberg finns mycket att upptäcka. Cyklingen fortsätter västerut mot en av västkustens mest älskade stränder; Tylösand. Här badar du extra tryggt eftersom stranden övervakas av professionella livräddare hela sommaren. Tylösand följs av Frösakull, Ringenäs och Vilshärads stränder innan leden svänger österut. Vid väderkvarnen i Särdal finns ett mysigt café och en gårdsbutik. Kring den charmiga byn Steninge finns flera vackra naturreservat med bland annat unika strandängar. Stanna gärna till på Steninge Kuststation för en fikapaus i Plätteriet eller i deras BIKEBAR. Leden tar dig vidare på Kustvägen till Ugglarp, där du kan göra en avstickare till Svedinos Bil- & Flygmuseum som har en av Europas största samlingar av gamla bilar och flygplan. Unna dig en skön bensträckare vid Ugglarps havsbad. Du cyklar vidare inåt landet och passerar böljande jordbrukslandskap. Här kan du stanna till vid utkiksplatsen Smörkull och Grimsholmens naturreservat innan du kommer fram till populära Skrea strand med sin långa badbrygga.",
        "information2": "Cyklingen fortsätter norrut mot en av Sveriges mest berömda och vackraste stränder,Tylösand. Om du inte vill ta ett bad på den av livräddare bevakade stranden, tycker jag att du ska besöka hotell Tylösand. På hotellet finns Sveriges största fotokonstgalleri och ett ”museum” som visar en del av Gyllene Tider och Roxettes historia. Leden följer kusten norrut och passerar Haverdal och Särdal. Stanna till på Steninge kuststation för en fika i Plätteriet eller Bikebar. Du behöver inte cykla så långt innan du kommer till Ugglarps Havsbad och Långasand, båda med fina bad och restauranger. Efter en kort tur inåt landet kommer du åter ut till kusten och Grimsholmen.Här är idealiskt för ett stopp och en kort promenad ut vattnet och kanske njuta av en medhavd matsäck. Du cyklar vidare och är snart framme i Falkenberg och Skrea Strand med sin långa badbrygga.",
        "surface": "76% asfalt, 24% grus"
      },
      {
        "route": "Etapp 6",
        "name": "FALKENBERG - VARBERG",
        "from": "Falkenberg",
        "to": "Varberg",
        "distance": "39 km",
        "status": "Det finns inga kända problem längs etappen.",
        "foodAndDrinks": "",
        "information": "Sträckan Falkenberg – Varberg är en lättcyklad del av Kattegattleden. Här cyklar du genom öppna landskap och upplever såväl orörd natur som populära stränder. Efter Falkenberg rullar du vidare längs en kuststräcka som verkligen ger liv åt uttrycket ”öppna landskap”. Här kan du stanna till vid mindre kända badplatser som Stafsinge strand och Petters brygga, eller njuta av stränder med mer liv och rörelse som Olofsbo, Björkäng och Apelviken. Perfekt när du behöver fylla på med glass eller en bit mat. Gör små stopp på vägen – här finns ett rikt fågelliv och flera naturreservat. Gör också en avstickare till Morups Tånge med den imponerande fyren som spridit sitt ljus över havet sedan 1843. På din väg passerar du också flera mysiga fiskelägen som Lövstaviken, Glommen och Träslövsläge, alla väl värda ett besök. När du närmar dig Varberg passerar du Apelviken, stranden som lockar massor av vindsurfare från hela Europa. Leden tar dig vidare till Strandpromenaden och förbi Varbergs fästning innan du når Varbergs Hamn med Kallbadhus och Societetspark.",
        "information2": "Sträckan Falkenberg-Varberg är en lättcyklad del av Kattegattleden, bara 40 km lång och inga backar att tala om. Du passerar flera små samhällen och byar. Stanna till i Olofsbo för att äta och dricka på Kvarnens Pensionat och restaurang, cykla ut till Morups Tånges naturreservat, titta på den vackra fyren och skåda på fåglar. Cykla en liten bit till och du är i Glommen, ett gammalt fiskeläge. Leden passerar många små byar och du är snart i Morup. Här kan du göra ett besök hos Kronmakaren Britten Toftarp. Du cyklar vidare på en relativt nyöppnad cykelbana, passerar Björkängs camping och i Utterås hittar du Rit och Målerigården. Ät en våffla och beundra all konst, kanske köpa nåt och skicka direkt hem.. Du närmar dig Träslövsläge, ett fiskeläge med flera matställen. Här måste du köpa och smaka på de underbara nykokta havskräftorna. Cykla vidare och du kommer till Apelviken, ett eldorado för vindsurfare. Leden går vidare till Strandpromenaden och när du passerat Varbergs fästning är du framme i Varbergs hamn med Kallbadhuset och Societetsparken.",
        "surface": "76% asfalt, 24% grus"
      },
      {
        "route": "Etapp 7",
        "name": "VARBERG - KUNGSBACKA",
        "from": "Varberg",
        "to": "Kungsbacka",
        "distance": "72 km",
        "status": "I Varberg bygger man just nu dubbelspår fr järnvägen. Delar av det nya järnvägsspåret ska läggas i tunnel under staden, en ny station och en ny bro ska byggas med mera. Ett stort och spännande projekt som tidvis kommer påverka dig som cyklar på Kattegattleden frbi eller i Varberg. Kommer du med cykeln på tåget, så håll hger när du gått ur från tågstationen och flj sedan hänvisningsskyltarna ner till leden.",
        "foodAndDrinks": "",
        "information": "Här cyklar du längs kusten, genom skogsmark och över jordbrukslandskap, genom små samhällen och i stadsmiljö. Leden är lätt kuperad där den följer småvägar och cykelvägar samt trafikerad vägar. Norr om Varberg förändras landskapet – sandstränder blir till klippor och de första öarna börjar dyka upp: Getterön, Balgö och Vendelsö. Leden tar dig ner till Bua hamn, ett fiskeläge där du kan prova på havets läckerheter. Genom Frillesås går leden i blandtrafik och fortsätter sedan på den gamla banvallen där du kan njuta av en bilfri sträcka längs kusten. Stanna till vid något av havsbaden i Åsa, ta en glass i caféet eller en eftermiddagsfika på bageriet. Gör en avstickare till Tjolöholms slott, Sveriges enda slott i tudorstil. Du cyklar vidare längs åkrar och hästgårdar. Stanna till på Rolfsbron i Hanhals, en vacker stenvalvsbro från 1600-talet. I Kungsbacka går leden genom staden som är en av Västsveriges största handelsplatser.",
        "information2": "Leden fortsätter längs kusten och går genom ängsmark, skogar och jordbrukslandskap. Den här sträckan är lätt kuperad men inga stora backar. Strax norr om Varberg ligger Naturum Getterön, ett måste stopp för den fågelintresserade. Du cyklar genom små samhällen och kommer snart till Bua, ett gammalt fiskeläge. I hamnen finns flera matställen som serverar fisk och skaldjur. Leden fortsätter förbi Ringhals kärnkraftverk och vidare genom vacker strandskog. Du fortsätter längs kusten och passerar Kärradal, Stråvalla och Frillesås, alla med utmärkta restauranger, caféer och fina badstränder. Stanna till i Åsa och varför inte ta en glass på Åsa Glasscafé. I Åsa fortsätter leden inåt landet och du cyklar på vindlande småvägar. Strax söder om Fjärås kan du avvika från leden och göra en avstickare till Tjolöholms slott, Sveriges enda slott i Tudorstil. Du cyklar vidare längs åkrar och hästgårdar. I Kungsbacka går leden genom staden som är en gammal handelsplats.",
        "surface": "95% asfalt, 5% grus"
      },
      {
        "route": "Etapp 8",
        "name": "KUNGSBACKA - GÖTEBORG",
        "from": "Kungsbacka",
        "to": "Göteborg",
        "distance": "64 km",
        "status": "Det finns inga kända problem längs etappen.",
        "foodAndDrinks": "",
        "information": "Kattegattleden mellan Kungsbacka och Göteborg bjuder på vackra naturupplevelser. Innan du möts av Göteborgs myllrande stadsliv cyklar du längs havet bland klippor och småbåtshamnar  Kattegattleden rullar vidare längs Kungsbackafjorden. På Båt- och Sjöfartsmuséet, strax nedanför kyrkan i Onsala, kan du få veta allt om ”Lasse i Gatan”, kungens kapare Lars Gathenhielm, som föddes år 1689. För öppettider se hemsidan.   Åk ner till Gottskär, det lilla samhället vid fjorden. Här kan du bada från salta klippor eller hoppa från hopptornet på Utholmen, strosa längs hamnen och insupa atmosfären. Du kan njuta av en god glass i något av glasscaféerna eller äta en bit mat på någon av de mysiga restaurangerna och caféerna runt Hamnplan eller varför inte ta in en natt på hotellet. I Särö roade sig kungligheter och Göteborgssocieteten under förra seklet. Gör en avstickare till Särö Västerskog där du kan promenera längs havet. När du lämnat badorten cyklar du på gamla Säröbanans banvall som går längs havet. Klippor, lummig skog, vikar och småbåtshamnar kantar din väg och här finns gott om badplatser.  Närmare Göteborg går leden förbi Nya Varvet, klippans kulturreservat och Röda Stens Konsthall. Vid Järntorget viker du in mot Göteborg city, passerar picknickvänliga Kungsparken och Stora Teatern. Sista sträckan går utmed Trädgårdsföreningen, en av Europas bäst bevarade 1800-talsparker, innan du når slutmålet på Drottningtorget vid Centralstationen.",
        "information2": "Från Kungsbacka centrum kan du endera följa Kattegattledens skyltning och cykla mot Onsala eller följa cykelbanan längs väg 158 till Vallda där du ansluter till Kattegattleden igen. Denna alternativa väg förkortar sträckan till Göteborg med ca 20 km. Om du väljer att följa ledsträckningen mot Onsala cyklar du längs Kungsbackafjorden. I Onsala kan du besöka Båt- och Sjöfartsmuseet och få lära känna ”Lasse i Gatan” , kungens kapare Lars Gathenhielm. Fortsätt till Gottskär och njut av en glass och promenad i den vackra hamnen. Du cyklar vidare över Onsalahalvön och fram till Särö. Från Särö cyklar du på gamla Säröbanans banvall som går längs havet. Du passerar många småsamhällen med restauranger, fikaställen och gott om badplatser. Norr om Billdal följer du kusten och har en vacker vy över skärgården. Cykla ut till Lilla Amundö, sträck på benen och njut av naturen. Närmare Göteborg passerar leden Klippans kulturreservat och Röda Stens Konsthall. Vid Järntorget viker du av in mot City och sträckan går utmed Kungsparken för att sluta på Drottningtorget vid Centralstationen.",
        "surface": "98% asfalt, 2% grus"
      }
    ]
}

export default routeData;

