import React, { useState } from 'react';
import Banner from '../Header/banner';
import Towns from './towns';
import InfoText from '../infotext/infotext';
import './start.css';

const Start = () => {

  const handleReadMore = () => {
    window.scrollTo({
      top: document.querySelector('.info-text-container').offsetTop,
      behavior: 'smooth'
    });
  }

  return (
    <div>
      <Banner />
      <div className='first-container'>
      <br/>
        <h1 className='header-text'>Välkommen till Cyklakattegattleden.se - din guide till en prisvärd cykelsemester! </h1>
        Kattegattleden sträcker sig över 390 kilometer från Helsingborg i Skåne till Göteborg i norr och erbjuder fantastiska vyer, kulturella upplevelser och många möjligheter till avkoppling längs vägen. Leden är Sveriges första nationella cykelled och vi vill att fler får möjlighet att upptäcka leden och cykelsemestrande på ett bekvämt sätt med bagagetransporter mellan varje boende.
        <br />
        <button className='read-more-button' onClick={handleReadMore}>Läs mer</button>
        <br /><br /><br />
        <h1 className='towns-title'>Hotell längst med Kattegattleden</h1>
        <Towns />
        <br /><br />
        <InfoText />
      </div>
    </div>
  );
}

export default Start;